import { type User } from '@prisma/client'
import {
  json,
  type LoaderFunctionArgs,
  type HeadersFunction,
  type LinksFunction,
  type MetaFunction,
} from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useMatches
} from '@remix-run/react'
import { withSentry } from '@sentry/remix'
import { ExternalScripts } from "remix-utils/external-scripts";
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { GeneralErrorBoundary } from './components/error-boundary.tsx'
import { useToast } from './components/toaster.tsx'
import { href as iconsHref } from './components/ui/icon.tsx'
import { EpicToaster } from './components/ui/sonner.tsx'
import MarketingLayout from './layout/marketing/MarketingLayout.tsx'
import UserLayout from './layout/user/UserLayout.tsx'
import tailwindStyleSheetUrl from './styles/tailwind.css?url'
import { getUserId, logout } from './utils/auth.server.ts'
import { ClientHintCheck, getHints } from './utils/client-hints.tsx'
import { APP_NAME, VETERAN_STATUS_VERIFIED } from './utils/constants.ts'
import { prisma } from './utils/db.server.ts'
import { getEnv } from './utils/env.server.ts'
import { honeypot } from './utils/honeypot.server.ts'
import { combineHeaders, getDomainUrl } from './utils/misc.tsx'
import { useNonce } from './utils/nonce-provider.ts'
import { type Theme, getTheme } from './utils/theme.server.ts'
import { makeTimings, time } from './utils/timing.server.ts'
import { getToast } from './utils/toast.server.ts'
import { hasActiveSubscription, useOptionalUser } from './utils/user.ts'

export const links: LinksFunction = () => {
  return [
    // Preload svg sprite as a resource to avoid render blocking
    { rel: 'preload', href: iconsHref, as: 'image' },
    // Preload CSS as a resource to avoid render blocking
    {
      rel: 'alternate icon',
      type: 'image/png',
      href: '/favicons/favicon-32x32.png',
    },
    { rel: 'apple-touch-icon', href: '/favicons/apple-touch-icon.png' },
    {
      rel: 'manifest',
      href: '/site.webmanifest',
      crossOrigin: 'use-credentials',
    } as const, // necessary to make typescript happy
    //These should match the css preloads above to avoid css as render blocking resource
    { rel: 'icon', type: 'image/svg+xml', href: '/favicons/favicon.svg' },
    { rel: 'stylesheet', href: tailwindStyleSheetUrl },
  ].filter(Boolean)
}

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  return [{ title: data ? APP_NAME : `Error | ${APP_NAME}` }]
}

export async function loader({ request }: LoaderFunctionArgs) {
  const timings = makeTimings('root loader')
  const userId = await time(() => getUserId(request), {
    timings,
    type: 'getUserId',
    desc: 'getUserId in root',
  })

  const user = userId
    ? await time(
      () =>
        prisma.user.findUniqueOrThrow({
          select: {
            id: true,
            firstName: true,
            lastName: true,
            email: true,
            createdAt: true,
            crmResponses: {
              where: {
                status: VETERAN_STATUS_VERIFIED,
              },
            },
            roles: {
              select: {
                name: true,
                permissions: {
                  select: { entity: true, action: true, access: true },
                },
              },
            },
            membership: {
              select: {
                id: true,
                status: true,
                arrivalAt: true,
                cardNumber: true,
                createdAt: true,
                branch: true,
                finalRank: true,
                dischargeCharacter: true,
                dischargeYear: true,
                passkitUrl: true,
              },
            },
            subscriptions: {
              select: {
                status: true,
                activeUntil: true,
              },
            },
          },
          where: { id: userId },
        }),
      { timings, type: 'find user', desc: 'find user in root' },
    )
    : null
  if (userId && !user) {
    console.info('user not found, logging out')
    // User is authenticated but we can't find them in the db. Maybe they were deleted? Let's log them out.
    await logout({ request, redirectTo: '/' })
  }
  const { toast, headers: toastHeaders } = await getToast(request)
  const honeyProps = honeypot.getInputProps()

  return json(
    {
      user,
      hasActiveSubscription: hasActiveSubscription(user?.subscriptions?.[0]),
      requestInfo: {
        hints: getHints(request),
        origin: getDomainUrl(request),
        path: new URL(request.url).pathname,
        userPrefs: {
          theme: getTheme(request),
        },
      },
      ENV: getEnv(),
      toast,
      honeyProps,
    },
    {
      headers: combineHeaders(
        { 'Server-Timing': timings.toString() },
        toastHeaders,
      ),
    },
  )
}

export const headers: HeadersFunction = ({ loaderHeaders }) => {
  const headers = {
    'Server-Timing': loaderHeaders.get('Server-Timing') ?? '',
  }
  return headers
}

function Document({
  children,
  nonce,
  theme = 'light',
  env = {},
  allowIndexing = true,
}: {
  children: React.ReactNode
  nonce: string
  theme?: Theme
  env?: Record<string, string>
  allowIndexing?: boolean
}) {
  const isProduction = env.MODE === 'production'

  return (
    <html
      lang="en"
      className={`${theme} h-full overflow-x-hidden scroll-smooth`}
    >
      <head>
        {isProduction && env.CLARITY_PROJECT_ID && (
          <script
            type="text/javascript"
            nonce={nonce}
            dangerouslySetInnerHTML={{
              __html: `
                (function(c,l,a,r,i,t,y){
                  c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                  t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                  y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                })(window, document, "clarity", "script", "${env.CLARITY_PROJECT_ID}");
              `,
            }}
          />
        )}
        <ClientHintCheck nonce={nonce} />
        <Meta />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        {allowIndexing ? null : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <Links />
      </head>
      <body className="bg-background text-foreground">
        {children}
        <script
          nonce={nonce}
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(env)}`,
          }}
        />
        <ScrollRestoration nonce={nonce} />
        <Scripts nonce={nonce} />
        <ExternalScripts />
      </body>
    </html>
  )
}

function App() {
  const data = useLoaderData<typeof loader>()
  const user = useOptionalUser()
  const nonce = useNonce()
  // const theme = useTheme()
  const allowIndexing = data.ENV.ALLOW_INDEXING !== 'false'
  useToast(data.toast)

  return (
    <Document
      nonce={nonce}
      // theme={theme}
      allowIndexing={allowIndexing}
      env={data.ENV}
    >
      <Layout user={user as unknown as User | null} />
      <EpicToaster
        closeButton
        position="top-center"
      // theme={theme}
      />
    </Document>
  )
}

function Layout({ user }: { user: User | null }) {
  const matches = useMatches()

  // Get the last cell from the `matches` array, check the `pathname` and see if it startsWith `/p/`. If it does, return `<Outlet />` otherwise render a layout.

  if (matches?.[matches.length - 1]?.pathname.startsWith('/p/')) {
    return <Outlet />
  }

  if (user) {
    return <UserLayout />
  }
  return <MarketingLayout />
}

function AppWithProviders() {
  const data = useLoaderData<typeof loader>()
  return (
    <HoneypotProvider {...data.honeyProps}>
      <App />
    </HoneypotProvider>
  )
}

export default withSentry(AppWithProviders)

export function ErrorBoundary() {
  // the nonce doesn't rely on the loader so we can access that
  const nonce = useNonce()

  // NOTE: you cannot use useLoaderData in an ErrorBoundary because the loader
  // likely failed to run so we have to do the best we can.
  // We could probably do better than this (it's possible the loader did run).
  // This would require a change in Remix.

  // Just make sure your root route never errors out and you'll always be able
  // to give the user a better UX.

  return (
    <Document nonce={nonce}>
      <GeneralErrorBoundary />
    </Document>
  )
}
